const abilities = {
  root: [
    {
      subject: 'all',
      action: 'manage',
    },
  ],

  manager: [
    {
      subject: 'Auth',
      action: 'read',
    },
    {
      subject: 'Dashboard',
      action: 'read',
    },
    {
      subject: 'Kanban',
      action: 'read',
    },
    {
      subject: 'Users',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'edit',
    },
    {
      subject: 'Conversations',
      action: 'read',
    },
    {
      subject: 'Settings',
      action: 'read',
    },
    {
      subject: 'Profile',
      action: 'edit',
    },
    {
      subject: 'Issues',
      action: 'read',
    },
    {
      subject: 'Planner',
      action: 'edit',
    },
  ],

  success: [
    {
      subject: 'Auth',
      action: 'read',
    },
    {
      subject: 'Dashboard',
      action: 'read',
    },
    {
      subject: 'Kanban',
      action: 'read',
    },
    {
      subject: 'Users',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'edit',
    },
    {
      subject: 'Settings',
      action: 'read',
    },
    {
      subject: 'Profile',
      action: 'edit',
    },
    {
      subject: 'Financial',
      action: 'read',
    },
    {
      subject: 'Tools',
      action: 'healthScore',
    },
    {
      subject: 'Issues',
      action: 'read',
    },
    {
      subject: 'Reports',
      action: 'recurrences',
    },
    {
      subject: 'Reports',
      action: 'total-clients',
    },
  ],

  relationship: [
    {
      subject: 'Auth',
      action: 'read',
    },
    {
      subject: 'Dashboard',
      action: 'read',
    },
    {
      subject: 'Kanban',
      action: 'read',
    },
    {
      subject: 'Users',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'edit',
    },
    {
      subject: 'Settings',
      action: 'read',
    },
    {
      subject: 'Profile',
      action: 'edit',
    },
    {
      subject: 'Issues',
      action: 'read',
    },
  ],

  service_qualification: [
    {
      subject: 'Auth',
      action: 'read',
    },
    {
      subject: 'Dashboard',
      action: 'read',
    },
    {
      subject: 'Kanban',
      action: 'read',
    },
    {
      subject: 'Users',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'edit',
    },
    {
      subject: 'Settings',
      action: 'read',
    },
    {
      subject: 'Profile',
      action: 'edit',
    },
    {
      subject: 'Tools',
      action: 'smarties',
    },
    {
      subject: 'Issues',
      action: 'read',
    },
  ],

  sales: [
    {
      subject: 'Auth',
      action: 'read',
    },
    {
      subject: 'Dashboard',
      action: 'read',
    },
    {
      subject: 'Kanban',
      action: 'read',
    },
    {
      subject: 'Users',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'edit',
    },
    {
      subject: 'Financial',
      action: 'read',
    },
    {
      subject: 'Settings',
      action: 'read',
    },
    {
      subject: 'Profile',
      action: 'edit',
    },
    {
      subject: 'Issues',
      action: 'read',
    },
    {
      subject: 'Reports',
      action: 'recurrences',
    },
  ],

  management_processes: [
    {
      subject: 'Auth',
      action: 'read',
    },
    {
      subject: 'Dashboard',
      action: 'read',
    },
    {
      subject: 'Kanban',
      action: 'read',
    },
    {
      subject: 'Kanban',
      action: 'list_all_managers',
    },
    {
      subject: 'Kanban',
      action: 'move_card',
    },
    {
      subject: 'Users',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'read',
    },
    {
      subject: 'Journey',
      action: 'edit',
    },
    {
      subject: 'Settings',
      action: 'read',
    },
    {
      subject: 'Profile',
      action: 'edit',
    },
    {
      subject: 'Tools',
      action: 'active-users',
    },
    {
      subject: 'Tools',
      action: 'activation-review',
    },
    {
      subject: 'Tools',
      action: 'migrate-user',
    },
    {
      subject: 'Tools',
      action: 'chat-follow',
    },
    {
      subject: 'Tools',
      action: 'import-data',
    },
    {
      subject: 'Tools',
      action: 'smarties',
    },
    {
      subject: 'Financial',
      action: 'read',
    },
    {
      subject: 'Financial',
      action: 'update',
    },
    {
      subject: 'Financial',
      action: 'list_all_managers',
    },
    {
      subject: 'Quota',
      action: 'update',
    },
    {
      subject: 'Issues',
      action: 'read',
    },
  ],
}

export default abilities
