import abilities from '../utils/ability'

export default class LoginService {
  // eslint-disable-next-line class-methods-use-this
  getAbility(userData) {
    if (userData.permission_type === 'manager') {
      return abilities.manager
    }

    if (userData.permission_type === 'sales') {
      return abilities.sales
    }

    if (userData.permission_type === 'success') {
      return abilities.success
    }

    if (userData.permission_type === 'relationship') {
      return abilities.relationship
    }

    if (userData.permission_type === 'service_qualification') {
      return abilities.service_qualification
    }

    if (userData.permission_type === 'management_processes') {
      return abilities.management_processes
    }

    if (userData.permission_type === 'root') {
      return abilities.root
    }

    return abilities.manager
  }

  // eslint-disable-next-line class-methods-use-this
  getRootAbility() {
    return abilities.manager
  }
}
