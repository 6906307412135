export default class fontDetectorService {
  // eslint-disable-next-line class-methods-use-this
  detect() {
    return document.fonts.ready.then(() => {
      const font = 'Hafidme Cms Usuario'
      const monospaceWidth = this.getWidth('monospace')
      const testWidth = this.getWidth(`'${font}', monospace`)

      return monospaceWidth !== testWidth
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getWidth(fontFamily) {
    const { body } = document
    const container = document.createElement('span')
    container.innerHTML = Array(100).join('wi')
    container.style.cssText = [
      'position:absolute',
      'width:auto',
      'font-size:128px',
      'font-weight: normal',
      'left:-99999px',
    ].join(' !important')

    container.style.fontFamily = fontFamily

    body.appendChild(container)
    const width = container.offsetWidth
    body.removeChild(container)

    return width
  }
}
