<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text ml-1">
            Hafidme
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Área administrativa
        </b-card-title>
        <b-card-text class="mb-2">
          Faça login para ter acesso as ferramentas administrativas
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit="login()"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="E-mail"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Senha</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>Esqueceu a senha?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Senha"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!--            <b-form-group>-->
            <!--              <b-form-checkbox-->
            <!--                id="remember-me"-->
            <!--                v-model="status"-->
            <!--                name="checkbox-1"-->
            <!--              >-->
            <!--                Lembrar-me-->
            <!--              </b-form-checkbox>-->
            <!--            </b-form-group>-->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || isLoading"
            >
              Entrar
              <b-spinner
                v-if="isLoading"
                small
              />
            </b-button>

            <small
              v-if="loginErrors"
              class="text-danger"
            >{{ loginErrors.join('. ') }}</small>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import LoginService from '@/services/loginService'
import FontDetectorService from '@/services/fontDetectorService'
import LocalStorageService from '@/services/localStorageService'
import useJwt from '@/auth/jwt/useJwt'
import crmJwt from '@/auth/jwt/crmJwt'
import toast from '@/mixins/toast'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BSpinner,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    // BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, toast],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      loginErrors: '',
      isLoading: false,
      fontExists: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    const fontDetectorService = new FontDetectorService()

    fontDetectorService.detect().then(response => {
      this.fontExists = response
    })
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          this.isLoading = true
          this.loginErrors = ''

          useJwt.login({
            email: this.userEmail,
            password: this.password,
            font: this.fontExists,
          })
            .then(response => {
              this.isLoading = false
              const loginService = new LoginService()

              const userData = response.data.data.user

              // definir abilities
              userData.ability = loginService.getAbility(userData)

              // salva o token de login do hafidme
              useJwt.removeLogin()
              useJwt.setToken(response.data.data.token)
              useJwt.setRefreshToken(response.data.data.token)
              LocalStorageService.setArray(LocalStorageService.userData, userData)

              // salva o token de login do crm
              crmJwt.removeLogin()
              crmJwt.setToken(response.data.data.token)
              crmJwt.setRefreshToken(response.data.data.token)

              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace({ name: 'cms-dashboard' })
                .then(() => {
                  this.showToast('success', `Bem-vindo ${userData.name}!`, `Você está logado como ${userData.permission_type}. Faça um bom uso!`, 'CoffeeIcon', 'top-center')
                })
            })
            .catch(error => {
              this.isLoading = false
              this.loginErrors = Object.entries(error.response.data.errors).reduce((carry, value) => ([...carry, value[1]]), [])
              if (this.loginErrors.length === 0) {
                this.loginErrors = [error.response.data.message]
              }
              // this.$refs.loginForm.setErrors(error.response.data.errors)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
